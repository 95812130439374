import React, { Component } from 'react'
import Layout from 'components/layout'
import get from 'lodash/get'
import Meta from 'components/meta'
import Axios from 'axios'

export default class contact extends Component {
  state = {
    email: '',
    message: '',
  }

  changeData = (data, value) => this.setState({ [data]: value })

  submitForm = async () => {
    const { email, message } = this.state
    if (!email) return alert('Please enter your email')
    if (!message) return alert('Please enter a message')

    await Axios.post(
      'https://dev-api.daily-deals.galacticapps.com/api/submit-form',
      { email, message }
    )

    alert('Message sent. Thank you for your feedback!')
  }
  render() {
    const { data, location } = this.props
    const { email, message } = this.state
    return (
      <Layout location={location}>
        <Meta
          title={'Leave Feedback | Daily Deals'}
          site={get(data, 'site.meta')}
        />
        <div className={`homepage-hero-background ${'bg-contact'}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1 className="hero-headline">How can we improve?</h1>
                <p className="hero-subheadline">
                  Please tell us more about the experience you had.
                </p>
                <input
                  onChange={event =>
                    this.changeData('email', event.target.value)
                  }
                  name="email"
                  required
                  type="email"
                  className="form-control"
                  placeholder="email address *"
                  style={{ marginBottom: 24 }}
                  value={email}
                />
                <textarea
                  onChange={event =>
                    this.changeData('message', event.target.value)
                  }
                  name="message"
                  className="form-control"
                  placeholder="What could we do better?"
                  rows="4"
                  required="required"
                  data-error="Please, leave us a message."
                  style={{ marginBottom: 24 }}
                  value={message}
                ></textarea>
                <button
                  type="submit"
                  onClick={this.submitForm}
                  className="btn login-button hero-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
